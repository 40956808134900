import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import Action from '../Action'
import ApiModel from '../ApiModel'
import { registerModelReducer } from '../reducers'
import { selectorCache } from '../util/selectorCache'
import {
  LOAD_SUCCESS_TYPE as USER_LOAD_SUCCESS_TYPE,
  selectMeId as selectMeUserId,
  selector as selectUser,
} from './User'
import dateCompare from '@app/js/lib/dateCompare'
import findFirst from '@app/js/lib/findFirst'
import { parseQuery, parseId } from './utilities'
import { ACTIVE_STATUSES, ALL_STATUSES } from 'joyable-js-api/src/models/Treatment.d.ts'

const MODEL = new ApiModel('TREATMENT', 'api/v1/treatments')
const NOT_FOUND = Symbol('Treatment not found')

export const DISPOSITIONS_SLUGS = {
  cancelled: {
    participantDecline: [
      'doesNotWantCoaching',
    ],
    adminDecline: [
      'benefitsDeclineNoCoverage',
      'outOfScopeActiveSi',
      'completedRemainingPlanYearSessions',
      'otherAdminDeclines',
    ],
    redirect: [
      'internalReferral',
      'outOfScopeInternal',
      'outOfScopeExternal',
    ],
  },
  inactive: {
    participantDecline: [
      'cannotCommitOneSession',
      'satisfiedRequestedToEndEarly',
    ],
    adminDecline: [
      'unableToContactMember',
      'otherKo',
    ],
  },
  finished: null,
}

registerModelReducer('treatments', (state = { }, { type, url }) => {
  if (type === USER_LOAD_SUCCESS_TYPE) {
    // once Me is loaded, if there aren't any treatments in the store we can assume it's because we
    // have no treatments, since they would have been sideloaded if we did.
    return { ...state, meLoaded: true }
  }
  if (type === Action.READ.successType(MODEL)) {
    const myTreatmentsLoaded = url === MODEL.getUrl()
    const activeTreatmentLoaded = parseId(MODEL, url) === 'active'
    const userId = parseQuery(url).user_id

    const userTreatmentsLoaded = !activeTreatmentLoaded && userId != null
      ? { ...state.userTreatmentsLoaded, [userId]: true }
      : { ...state.userTreatmentsLoaded }

    const res = { ...state, myTreatmentsLoaded, userTreatmentsLoaded }
    return res
  }

  return state
})

const PROVIDER_SHAPE = PropTypes.shape({
  email: PropTypes.string,
  full_name: PropTypes.string,
  gender: PropTypes.string,
  years_experience: PropTypes.number,
  license: PropTypes.string,

  // Filled in on the client, at the selector level from full name and license depending on the
  // provider type
  displayName: PropTypes.string.isRequired,
})

const SHAPE = PropTypes.shape({
  status: PropTypes.oneOf(ALL_STATUSES),
  product_line: PropTypes.string,
  program: PropTypes.string,
  is_self_guided: PropTypes.bool,
  is_graduated: PropTypes.bool,
  session_protocol_code: PropTypes.string,
  final_protocol_code: PropTypes.string,
  behavioral_coach: PROVIDER_SHAPE,
  therapist: PROVIDER_SHAPE,
  previous_status: PropTypes.oneOf(ALL_STATUSES),
  e2_treatment_id: PropTypes.string,
})

const select = ({ api: { treatments } }) => (treatments && Object.values(treatments)) ?? []

const selectMeLoaded = state => state.models.treatments.meLoaded
const selectMyTreatmentsLoaded = state => state.models.treatments.allTreatmentsLoaded
const selectUserTreatmentsLoaded = userId => state => state.models.treatments.userTreatmentsLoaded?.[userId]

const isForUser = searchUserId => ({ user_id: userId }) => userId === searchUserId
const isActive = ({ status }) => ACTIVE_STATUSES.includes(status)
const findMostRecent = treatments => findFirst(treatments, dateCompare(false, 'updated_at'))
const findByTreatmentId = (treatments, treatmentId) =>
  treatments.find(treatment => treatment.e2_treatment_id === treatmentId)

const getCurrentTreatment = treatments => {
  if (!Array.isArray(treatments) || treatments.length === 0) return null

  const activeTreatment = findMostRecent(treatments.filter(isActive))
  if (activeTreatment) return activeTreatment

  const latestTreatment = findMostRecent(treatments)
  if (latestTreatment) return latestTreatment

  return null
}
const displayName = ({ full_name: fullName, license }, providerType) => providerType === 'therapist'
  ? `${fullName}, ${license}`
  : fullName
const withDisplayName = (treatment, providerType) => treatment[providerType] == null
  ? null
  : ({
      ...treatment[providerType],
      displayName: displayName(treatment[providerType], providerType),
    })
const withDisplayNames = treatment => treatment && ({
  ...treatment,
  therapist: withDisplayName(treatment, 'therapist'),
  behavioral_coach: withDisplayName(treatment, 'behavioral_coach'),
})

const selectMyTreatments = createSelector(
  [selectMeUserId, select],
  (meId, treatments) => treatments.filter(isForUser(meId)).map(withDisplayNames),
)

const selectConnection = createSelector(
  [selectMyTreatments, selectMeLoaded, MODEL.isNotFoundSelector({ id: 'active' })],
  (treatments, meLoaded, notFound) => ({
    activeTreatment: notFound
      ? NOT_FOUND
      : withDisplayNames(treatments.find(isActive)) ?? (meLoaded ? NOT_FOUND : null),
  }),
)

const selectCurrentConnection = createSelector(
  [selectMyTreatments, selectMeLoaded, selectMyTreatmentsLoaded],
  (treatments, meLoaded, allLoaded) => ({
    currentTreatment: withDisplayNames(getCurrentTreatment(treatments)) ??
      (meLoaded || allLoaded ? NOT_FOUND : null),
  }),
)

const selectE2TreatmentIdConnection = selectorCache(
  ({ e2TreatmentId, userId }) => `${e2TreatmentId}|${userId}`,
  ({ e2TreatmentId, userId }) => {
    return createSelector(
      [select, selectUserTreatmentsLoaded(userId)],
      (treatments, treatmentsLoaded) => ({
        treatment: findByTreatmentId(treatments, e2TreatmentId) ?? (treatmentsLoaded ? NOT_FOUND : null),
      }),
    )
  },
)

const clearUpdateError = (id) => {
  ApiModel.dispatch(
    Action.UPDATE_MODIFY.getClearPendingAction(MODEL, MODEL.getUrl({ id })),
  )
}

export const changeStatusReason = ({ status, tier2, tier3 }) => {
  if (!tier2 && !tier3) {
    return { reason: status }
  } else {
    return {
      reason: { [status]: { [tier2]: tier3 } },
    }
  }
}

const selectCurrentForUser = selectorCache(
  ({ userId }) => userId,
  ({ userId }) => createSelector(
    [select, selectUser(userId)],
    (treatments, user) => {
      const userLoaded = user != null
      const currentTreatment = getCurrentTreatment(treatments.filter(isForUser(userId)))
      return {
        currentTreatmentForUser: currentTreatment ?? (userLoaded ? NOT_FOUND : null),
        updateTreatment: ({ treatment }) => {
          const id = currentTreatment ? currentTreatment.id : user.treatment_id
          clearUpdateError(id)
          return MODEL.updateModify({ treatment }, { id }).then((res) => {
            if (!res?.error) {
              user.current_treatment_status = treatment.status
            }
          })
        },
      }
    },
  ),
)

export const Treatment = {
  SHAPE,
  PROVIDER_SHAPE,
  NOT_FOUND,

  connection: {
    load: ({ activeTreatment }) => {
      if (activeTreatment == null) MODEL.read({ id: 'active' })
    },

    isLoaded: ({ activeTreatment }) => activeTreatment != null,

    selector: selectConnection,

    shape: {
      activeTreatment: PropTypes.oneOfType([PropTypes.symbol, SHAPE]),
    },
  },

  currentConnection: {
    load: ({ currentTreatment }) => {
      if (currentTreatment == null) MODEL.read()
    },

    isLoaded: ({ currentTreatment }) => currentTreatment != null,

    selector: selectCurrentConnection,

    shape: {
      currentTreatment: PropTypes.oneOfType([PropTypes.symbol, SHAPE]),
    },
  },

  e2TreatmentIdConnection: {
    load: ({ treatment }, { userId }) => {
      if (treatment == null) MODEL.read({ query: { user_id: userId } })
    },

    isLoaded: ({ treatment }) => treatment != null,

    selector: selectE2TreatmentIdConnection,

    shape: {
      treatment: PropTypes.oneOfType([PropTypes.symbol, SHAPE]),
    },
  },

  currentForUserConnection: {
    load: ({ currentTreatmentForUser }, { userId }) => {
      if (currentTreatmentForUser == null) MODEL.read({ query: { user_id: userId } })
    },
    isLoaded: ({ currentTreatmentForUser }) => currentTreatmentForUser != null,
    selector: selectCurrentForUser,
  },
}
