import { createSelector } from 'reselect'
import { registerModelReducer } from '../reducers'
import Action from '../Action'
import ApiModel from '../ApiModel'
import moment from 'moment'
import * as COLORS from '@ableto/component-library/theme/colors'
import { COLOR_GENERATOR_COLORS } from '../util/colorGeneratorColors'

const MODEL = new ApiModel('CALENDAR_EVENTS', 'admin/api/v1/calendar_events')

const getDuration = (endString, startString) => {
  const end = moment(endString)
  const start = moment(startString)

  // Get the duration in minutes
  const duration = moment.duration(end.diff(start))

  return duration._data
}

registerModelReducer('calendar_events', (state = null, props) => {
  const { type, response } = props
  switch (type) {
    case Action.READ.successType(MODEL): {
      // Adds to the store the original events as in database
      const newState = response.calendar_events.reduce((state, event) => {
        state[event.id] = event
        return state
      }, {})
      return newState
    }
    case Action.CREATE.successType(MODEL): {
      const newEvent = response.calendar_event
      return {
        ...state,
        [newEvent.id]: newEvent,
      }
    }
    case Action.UPDATE_MODIFY.successType(MODEL): {
      const updatedEvent = response.calendar_event

      return {
        ...state,
        [updatedEvent.id]: updatedEvent,
      }
    }
    case Action.DESTROY.successType(MODEL): {
      const deletedId = response.calendar_event.id
      const { [deletedId]: _, ...newState } = state

      return newState
    }
    default:
      return state
  }
})

registerModelReducer('calendar_current_coach_ids', (state = {}, props) => {
  const { type, url } = props
  switch (type) {
    case Action.READ.successType(MODEL): {
      const urlParams = new URLSearchParams(url.split('?')[1])
      const coachIdsStr = urlParams.get('coach_ids') || ''

      // Adds to the store, all selected coach ids taking from the url
      const coachIds = coachIdsStr.split(',').map(idStr => parseInt(idStr))
      return coachIds
    }
    default:
      return state
  }
})

const addCalendarEvent = async (data) => {
  const formattedCalendarEvent = {
    title: data.title,
    starts_at: data.startStr,
    all_day: data.allDay,
    ends_at: data.endStr,
    event_type: data.eventType,
    description: data.description,
    coach_id: data.coachId,
    rrule: data.rrule,
  }
  const newEvent = {
    calendar_event: formattedCalendarEvent,
  }
  await MODEL.create(newEvent, { ignoreFailure: true })
}

const updateCalendarEvent = async (data, id) => {
  const formattedCalendarEvent = {
    title: data.title,
    starts_at: data.startStr,
    all_day: data.allDay,
    ends_at: data.endStr,
    event_type: data.eventType,
    description: data.description,
    coach_id: data.coachId,
    rrule: data.rrule,
  }
  const updatedEvent = {
    calendar_event: formattedCalendarEvent,
  }
  await MODEL.updateModify(updatedEvent, { id, ignoreFailure: true })
}

const deleteCalendarEvent = async (id) => {
  if (!id) return
  await MODEL.destroy({ id, ignoreFailure: true })
}

const getCalendarEvents = async (ids, startDate, endDate) => {
  await MODEL.read({
    query: {
      coach_ids: ids.join(','),
      from: startDate,
      to: endDate,
    },
  })
}

const setEventColor = (eventType) => {
  switch (eventType) {
    case 'holiday':
      return COLORS.primary300
    case 'meeting':
      return COLORS.primary400
    case 'out_of_office':
      return COLORS.primary600
    case 'personal':
      return COLORS.primary800
    case 'session':
      return COLORS.teal600
    default:
      return COLORS.primary800
  }
}

const assignColorToSelected = (coaches, colors, selectedCoachesIds) => {
  const coachColorMap = {}
  const usedColors = new Set() // to track which colors have been assigned
  const selectedCoaches = coaches ? coaches.filter(coach => selectedCoachesIds.includes(coach.id)) : []

  // Loop over selected coaches and assign unique colors when possible
  selectedCoaches.forEach((coach, index) => {
    let newColor

    // Try to assign a color that hasn't been used yet
    if (!usedColors.has(coach.color)) {
      newColor = coach.color
      usedColors.add(coach.color)
      coachColorMap[coach.id] = newColor
    } else {
      for (const color of colors) {
        if (!usedColors.has(color)) {
          newColor = color
          usedColors.add(color)
          break
        }
      }
      coachColorMap[coach.id] = newColor
    }

    // If no unique color left, use original coach color
    if (!newColor) {
      newColor = coach.color
      usedColors.add(coach.color)
      coachColorMap[coach.id] = newColor
    }
  })

  return coachColorMap
}

const selectCalendarEvents = createSelector(
  [
    state => state.models.calendar_events,
    state => state.models.calendar_current_coach_ids,
    state => state.api?.coaches?.me,
    state => state.models.calendar_coaches,
    (_state, isSingleResource) => isSingleResource,
  ],
  (originalCalendarEvents, calendarCoachIds, currentCoachId, coaches, isSingleResource) => {
    if (!originalCalendarEvents) return { calendarEvents: null, coachColorMap: null }

    // Recreate calendar events
    const events = Object.values(originalCalendarEvents)
    const coachColorMap = assignColorToSelected(coaches, COLOR_GENERATOR_COLORS, calendarCoachIds)

    const calendarEvents = events.map(originalEvent => {
      const {
        all_day: allDay,
        ends_at: end,
        event_type: eventType,
        starts_at: start,
        profile_image: profileImage,
        coach_id: coachId,
        participant_id: participantId,
        ...eventFields
      } = originalEvent

      return {
        ...eventFields,
        allDay,
        end,
        eventType,
        start,
        coachId,
        participantId,
        // Duration needs to be set so the component can set end values
        duration: originalEvent.rrule ? getDuration(end, start) : undefined,
        // Set event colors
        color: !isSingleResource
          ? setEventColor(eventType)
          : coachColorMap[originalEvent.coach_id],
      }
    })
    return { calendarEvents, coachColorMap }
  },
)

const selectCalendarEventsModel = createSelector(
  [
    (state) => selectCalendarEvents(state, false),
    (state) => MODEL.getReadErrorMessage(state),
  ],
  ({ calendarEvents }, calendarEventsError) => ({
    calendarEvents,
    calendarEventsError,
    addCalendarEvent,
    updateCalendarEvent,
    deleteCalendarEvent,
    getCalendarEvents,
  }),
)

const selectResourceCalendarEventsModel = (isSingleResource) => createSelector(
  [
    (state) => selectCalendarEvents(state, isSingleResource),
    (state) => MODEL.getReadErrorMessage(state),
  ],
  ({ calendarEvents, coachColorMap }, calendarEventsError) => ({
    calendarEvents,
    coachColorMap,
    calendarEventsError,
    addCalendarEvent,
    updateCalendarEvent,
    deleteCalendarEvent,
    getCalendarEvents,
  }),
)

export const CalendarEvents = {
  connection: {
    selector: selectCalendarEventsModel,
  },
  singleResourceConnection: {
    selector: selectResourceCalendarEventsModel(false),
  },
  resourceConnection: {
    selector: selectResourceCalendarEventsModel(true),
  },
}
